import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
} from "@apollo/client";
import { ApolloLink } from "apollo-link";
import Pusher from "pusher-js";

import PusherLink from "./pusherLink"

const httpLink = createHttpLink({ uri: "/graphql" });

// Setup pusher link for graphql subscriptions
const pusherClient = new Pusher("a5c19937d10212cddb4e", {
  cluster: "us2",
  forceTLS: true,
});
const pusherLink = new PusherLink({ pusher: pusherClient });

const link = ApolloLink.from([pusherLink, httpLink]);

// Initialize ApolloClient
const client = new ApolloClient({
  link,
  cache: new InMemoryCache({
    typePolicies: {
      VideoMarker: {
        keyFields: ["id"],
      },
    },
  }),
});

export default client;
