import React , { useState, useContext, useEffect } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom'
import {UserContext} from "../Shared/UserContext.js";
import axios from "axios";
import ReactTooltip from 'react-tooltip';
import { Modal } from 'react-responsive-modal';
import ImportSubscriptions from "../Onboardings/Sellers/ImportSubscriptions.js";
import Btn from "../Shared/Btn.js";
import {ProjectContext} from "../Projects/ProjectContext.js";
import Select from 'react-select';
import Filestack from "./Filestack.js";


const New = ({onCloseModal, organization_id, chatroom_id, buyer, seller, refetchData}) => {
  const match = useRouteMatch();
  const history = useHistory();
  const {groups} = useContext(ProjectContext);
  const [disabled, setDisabled] = useState(false)
  const [title, setTitle] = useState("")
  const currentUser = useContext(UserContext)
  const [isPrivate, setIsPrivate] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [stripeAccountPresent, setStripeAccountPresent] = useState(false)
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [logo, setLogo] = useState(null);
  const [open, setOpen] = useState(false);
  const [clientEmail, setClientEmail] = useState(null);

  useEffect(() => {
    setLoaded(true);
  }, []);

  const handleSubmit = async (evt) => {
    setDisabled(true)
    evt.preventDefault();
    try {
      const data = formData("new_project");
      data.title = title; 
      data.chatroom_id = chatroom_id;
      data.private = isPrivate;
      data.service_id = selectedService;
      data.group_id = selectedGroup?.value;
      data.logo = logo;
      data.client_email = clientEmail;
      if((buyer != null) && (seller != null) ){
        data.sender_organization_id = document.getElementById("sender_organization_id").value 
        data.seller_organization_id = document.getElementById("seller_organization_id").value
      }

      const response = await axios.post(`/api/o/${organization_id || currentUser.primary_organization.token}/projects`, data)

      console.log(response);
      history.push(`/o/${response.data.organization.token}/projects/${response.data.project.token}`)
      await fetchProjectsAsync();
      onCloseModal();
      setDisabled(false);

    } catch (error) {
      console.log(error)
      notice("An error occured")
    }
  };

  const handleGroupSelect = (selectedOption) => {
    setSelectedGroup(selectedOption);
  };

  const groupOptions = groups.map(group => ({
    value: group.token,
    label: group.title
  }));


  return(
    loaded ?(
      <React.Fragment>
        <h4 className="modal-title color-black-always">New client project</h4>

        <div className="row no-margin">
          <Filestack project={null} setImage={setLogo} image={logo || "https://g-epdw4cnc0di.vusercontent.net/placeholder.svg"}/>

          <form id="new_project" onSubmit={handleSubmit}>
            <div className="field">
              <label className="color-black-always">Title</label>
              <input onChange={(e) => setTitle(e.target.value)} name="title" className="form-control mb-10" placeholder="Client or company name" required autoFocus />
            </div>

            <div className="field">
              <label className="color-black-always">Invite client</label>
              <input onChange={(e) => setClientEmail(e.target.value)} type="email" className="form-control mb-10" placeholder="Email of your client (optional)" />
            </div>

            {groups.length > 0 && 
              <div className="field">
                <label>Group</label>
                <Select
                  options={groupOptions}
                  onChange={handleGroupSelect}
                  className="min-width-350"
                  placeholder="Select..."
                  classNamePrefix="react-select"
                />
              </div>
            }

            <div className="field">
              <div className="replay-marker-color-box">
                <input name="video_marker[private]" type="hidden" value="0" />
                <input className="apple-switch color-white-5" style={{ top: "3px" }} type="checkbox" checked={isPrivate} onChange={(e) => setIsPrivate(e.target.checked)} />

                <span className="color-2 dark-color-black mr-5 ml-5" variant="success" style={{marginLeft: "5px"}}>
                  Make private
                  <i class="far fa-question-circle ml-8 color-2" data-tip="Only you will be able to see and access the project."></i>
                  <ReactTooltip effect="solid" backgroundColor="black" textColor="white" delayShow={200}/>
                </span>
              </div>
            </div>
            
            <div className="field text-right">
              <Btn className="btn btn-primary" type="submit" disabled={disabled} disable={title === ""} text="Submit"/>
            </div>
          </form>
          
          {stripeAccountPresent && 
            <div className="field text-center">
              <div className="line-or-wrapper">
                <div class="line"></div>
                <div class="or">or</div>
                <div class="line"></div>
              </div>
              
              <a onClick={() => {setOpen(true);} } className="btn btn-small background-hover color-1 display-block mt-25"><i className="fab fa-stripe-s mr-5"></i>Import subscriptions from Stripe</a>
              
              <Modal open={open} onClose={() => setOpen(false)} className="modal-body-white" classNames={{modal: 'width-800'}} center>
                <h4 className="color-black-always modal-title">Import subscriptions</h4>
                <p className="modal-description mb-30">We'll import the subscription and send an invite link to your client if you wish.</p>
                <ImportSubscriptions setImportFinished={() => {onCloseModal}} refetchData={refetchData} project_id={null}/>
              </Modal>
            </div>
          }
        </div>
      </React.Fragment>
    ):(
      <div className="flex-center-no-width large-padding"><i className="fas fa-spinner fa-spin"></i></div>
    )
  )
}

export default New