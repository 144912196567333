import React , { useState, useEffect } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import i18n from '../../i18n/locales/i18n.js'; // Import the i18n instance

const LocalizationCountries = ({}) => {
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(null);

  const countries = [
    {
      flagUrl: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/US.svg",
      language: "English",
      abbreviation: "en"
    },
    {
      flagUrl: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/SA.svg",
      language: "العربية",
      abbreviation: "ar"
    },
    {
      flagUrl: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/BG.svg",
      language: "Bulgarian",
      abbreviation: "bg"
    },
    {
      flagUrl: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/BD.svg",
      language: "বাংলা",
      abbreviation: "bn"
    },
    {
      flagUrl: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/BR.svg",
      language: "Português (Brasil)",
      abbreviation: "pt-BR"
    },
    {
      flagUrl: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/DE.svg",
      language: "Deutsch",
      abbreviation: "de"
    },
    {
      flagUrl: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/DK.svg",
      language: "Dansk",
      abbreviation: "da"
    },
    {
      flagUrl: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/ES.svg",
      language: "Español",
      abbreviation: "es"
    },
    {
      flagUrl: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/FI.svg",
      language: "Suomi",
      abbreviation: "fi"
    },
    {
      flagUrl: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/FR.svg",
      language: "Français",
      abbreviation: "fr"
    },
    {
      flagUrl: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/IN.svg",
      language: "हिन्दी",
      abbreviation: "hi"
    },
    {
      flagUrl: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/IT.svg",
      language: "Italiano",
      abbreviation: "it"
    },
    {
      flagUrl: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/JP.svg",
      language: "日本語",
      abbreviation: "ja"
    },
    {
      flagUrl: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/KR.svg",
      language: "한국어",
      abbreviation: "ko"
    },
    {
      flagUrl: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/NL.svg",
      language: "Nederlands",
      abbreviation: "nl"
    },
    {
      flagUrl: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/NO.svg",
      language: "Norsk",
      abbreviation: "no"
    },
    {
      flagUrl: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/PL.svg",
      language: "Polski",
      abbreviation: "pl"
    },
    {
      flagUrl: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/RO.svg",
      language: "Română",
      abbreviation: "ro"
    },
    {
      flagUrl: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/TR.svg",
      language: "Türkçe",
      abbreviation: "tr"
    },
    {
      flagUrl: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/SE.svg",
      language: "Svenska",
      abbreviation: "sv"
    },
    {
      flagUrl: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/RU.svg",
      language: "Русский",
      abbreviation: "ru"
    },
    // Add more countries as needed
  ];  
  

  useEffect(() => {
    checkActive()
    setLoaded(true);
  }, []); // Run once on component mount

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    localStorage.setItem('language', language);
    checkActive();
  };
  
  const checkActive = () => {
    const storedLanguage = localStorage.getItem('language'); // Get language from local storage
    if (storedLanguage) {
      const foundLanguage = countries.find(country => country.abbreviation === storedLanguage);
      setSelectedLanguage(foundLanguage);
    } else {
      // Get the browser's default language
      const browserLanguage = navigator.language.split('-')[0]; // Extract the language code
      const defaultLanguage = countries.find(country => country.abbreviation === browserLanguage);
      setSelectedLanguage(defaultLanguage);
    }
  }
  

  const countries_list = countries.map(country => {
    return(
      <li>
        <a onClick={() => changeLanguage(country.abbreviation)}>
          <img src={country.flagUrl} style={{width: 20, marginRight: 15}}/>
          {country.language}
        </a>
      </li>
    )
  })

  return(
    <React.Fragment>
      <div class="btn-group dropup mr-5">
        <a type="button" class="btn btn-small color-1 background-hover dropdown-toggle" data-toggle="dropdown">
          {selectedLanguage && (
            <img src={selectedLanguage.flagUrl} alt={selectedLanguage.language} style={{ width: 15, marginRight: 5 }}/> 
          )}
          {selectedLanguage ? selectedLanguage.abbreviation.toUpperCase() : "EN"}
        </a>
        <ul class="dropdown-menu animated fadeInUp" role="menu" style={{maxHeight: "422px", overflow: "auto"}}>
          {countries_list}
        </ul>
      </div>
    </React.Fragment>
  )
}

export default LocalizationCountries;