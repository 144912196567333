import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './en.json';
import fr from './fr.json';
import ko from './ko.json';
import ja from './ja.json';
import de from './de.json';
import es from './es.json';
import esLA from './es-LA.json';
import ptBR from './pt-BR.json';
import fi from './fi.json';
import da from './da.json';
import nl from './nl.json';
import no from './no.json';
import sv from './sv.json';
import pl from './pl.json';
import ro from './ro.json';
import it from './it.json';
import hi from './hi.json';
import bn from './bn.json';
import ru from './ru.json'; // Russian
import ar from './ar.json'; // Arabic
import tr from './tr.json'; // Turkish
import bg from './bg.json'; // Turkish

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: en,
      },
      fr: {
        translation: fr,
      },
      ko: {
        translation: ko,
      },
      ja: {
        translation: ja,
      },
      de: {
        translation: de,
      },
      es: {
        translation: es,
      },
      'es-LA': {
        translation: esLA,
      },
      'pt-BR': {
        translation: ptBR,
      },
      fi: {
        translation: fi,
      },
      da: {
        translation: da,
      },
      nl: {
        translation: nl,
      },
      no: {
        translation: no,
      },
      sv: {
        translation: sv,
      },
      pl: {
        translation: pl,
      },
      ro: {
        translation: ro,
      },
      it: {
        translation: it,
      },
      hi: {
        translation: hi,
      },
      bn: {
        translation: bn,
      },
      ru: {
        translation: ru,
      },
      ar: {
        translation: ar,
      },
      tr: {
        translation: tr,
      },
      bg: {
        translation: bg,
      }
    },
    lng: 'en', // Default language
    fallbackLng: 'en', // Fallback language
    interpolation: {
      escapeValue: false, // React already safes from XSS
    },
  });

export default i18n;
