import React , { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import Edit from "./Edit.js";
import {UserContext} from "../Shared/UserContext.js";
import {OrganizationContext} from "../Shared/OrganizationContext.js"

const ProjectDropdown = ({project}) => {
  const history = useHistory();
  const [openProjectEdit, setOpenProjectEdit] = useState(false);
  const {organizationAuthorizations, hasPermission} = useContext(OrganizationContext);

  const archive = async () => {
    try {
      const response = await axios.post(`/api/o/${project.organization.token}/projects/${project.token}/project_archives`)
      console.log(response);
      if(response.data.success){
        await fetchProjectsAsync();
        notice("Project has been archived");

        if(location.href.includes(response.data.project.token)){
          history.push(`/o/${project.organization.token}/projects`);
        }
      }
    } catch (error) {
      console.log(error)
      notice("An error occured")
    }
  }

  const deleteProject = async (evt) => {
    evt.preventDefault();

    if (confirm("Are you sure? We can't undo this.")) {
      try {
        const response = await axios.delete(`/api/o/${project.organization.token}/projects/${project.token}`);
  
        console.log(response);
  
        if (response.data.success) {
          notice("Project deleted");
          await fetchProjectsAsync();
        }
        
        if (location.href.includes(project.token)) {
          history.push(`/o/${project.organization.token}/projects`);
        }

      } catch (error) {
        console.error(error);
        notice("An error occurred while deleting the project.");
      }
    }
  }

  const leaveProject = () => {
    axios.post(`/api/o/${project.organization.token}/projects/${project.token}/project_users/leave_project`)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        location.href = "/"
        notice("Left project")
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {

    });
  }

  return(
    <React.Fragment>
      <div className="dropdown hover-child-opacity">
        <i className="dropdown-toggle fas fa-ellipsis-v sidebar-text-color cursor-pointer opacity-4 opacity-10-hover btn btn-small background-hover color-1" data-toggle="dropdown" style={{padding: "0px 10px"}}></i>
        <ul className="dropdown-menu animated fadeInUp pull-right" style={{minWidth: "200px", right: '-10px', top: '25px'}}>
          <i className="fas fa-caret-up navbar-dropdown-arrow "></i>

          <li>
            <a onClick={archive} className="project-show-edit color-5" style={{paddingLeft: "10px", paddingRight: "10px"}}>
              <i class="fal fa-eye-slash"></i>Hide
            </a>
          </li>
          
          {organizationAuthorizations.organization.organization_user && 
            <React.Fragment>
              <li>
                <a onClick={() => setOpenProjectEdit(true)} className="project-show-edit color-5" style={{paddingLeft: "10px", paddingRight: "10px"}}><i class="fas fa-pencil"></i>Edit</a>

                <Modal open={openProjectEdit} onClose={() => setOpenProjectEdit(false)}  className="modal-body-white" classNames={{modal: 'width-500'}} center>
                  <Edit onCloseModal={() => setOpenProjectEdit(false)} project={project} closeModal={() => setOpenProjectEdit(false)}/>
                </Modal>
              </li>
              
              {hasPermission("delete_project") && 
                <li>
                  <a onClick={deleteProject} className="project-show-edit color-5" style={{paddingLeft: "10px", paddingRight: "10px"}}>
                    <i class="fal fa-trash"></i>Delete
                  </a>
                </li>
              }
            </React.Fragment>
          
          }

          {!organizationAuthorizations.organization.organization_user && 
            <li>
              <a className="project-show-edit color-5" style={{paddingLeft: "10px", paddingRight: "10px"}} onClick={leaveProject}><i class="fal fa-sign-out-alt"></i> Leave project</a>
            </li>
          }
        </ul>
      </div>
    </React.Fragment>
  )
}

export default ProjectDropdown