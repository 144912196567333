import React, {useState} from 'react';
import { PickerOverlay } from 'filestack-react';
import { Modal } from 'react-responsive-modal';

const Filestack = ({ project, setImage, image }) => {
  const [open, setOpen] = useState(false);

  const createReplay = (filestack) => {
    const filesUploaded = filestack.filesUploaded;
    const imageFiles = Object.values(filesUploaded).filter(file => file.mimetype.startsWith('image/'));
    if (imageFiles.length > 0) {
      const imageFile = imageFiles[0];
      console.log(imageFile);
      setImage(imageFile.url);
    } else {
      console.log('No image file uploaded');
    }
  }

  return (
    <React.Fragment>
      <div className="display-flex field align-items-center">
        <img src={image} className="project-list-avatar" style={{width: 30, height: 30}}/>
        <a onClick={() => setOpen(true)} className="btn btn-secondary btn-small">
          Add logo
        </a>
      </div>

        {open && 
          <PickerOverlay
            apikey={"AKVhxMbEQhkOIryqBvSEQz"}
            onSuccess={(res) => console.log(res)}
            onUploadDone={(res) => {
              createReplay(res);
            }}
            clientOptions={{
              sessionCache: true
            }}
            pickerOptions={{
              onClose: () => {
                setOpen(false);
              }, 
              fromSources: ["local_file_system", "googledrive", "dropbox", "box", "onedrive", "onedriveforbusiness", "googlephotos", "facebook", "instagram"],
              maxFiles: 1,
              accept: 'image/*',
              storeTo: {
                location: 'gcs',
                path: '/',
                container: 'queue-filestack'
              }
            }}
          />
        }
        hehe hey there!
    </React.Fragment>
  );
}

export default Filestack;
