import React , { useState, useEffect, useRef } from 'react';
import axios from "axios"; 
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { ReactMultiEmail } from 'react-multi-email';
import SettingsTab from "./SettingsTab.js";
import Approved from "./FeedbackLinks/Approved.js";
import Edit from "./FeedbackLinks/Edit.js";
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';

const Share = ({refetchData, replay, index}) => {
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [copied, setCopied] = useState(false);
  const [emails, setEmails] = useState([]);
  const [focused, setFocused] = useState(false);
  const [sent, setSent] = useState([]);
  const [approvals, setApprovals] = useState([]);
  const [typing, setTyping] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [showShared, setShowShared] = useState(false);
  const [defaultIndex, setDefaultIndex] = useState(index || 1)
  const { t } = useTranslation();

  useEffect(() => {
    fetchSent();
    fetchApprovals();
  }, []);

  const fetchSent = () => {
    axios.get(`/api/replays/${replay.token}/feedback_links.json`)
    .then(function(response){
      // console.log(response);
      if(response.data.success){
        setSent(response.data.feedback_links)
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      // always executed
    });
  }

  const fetchApprovals = () => {
    axios.get(`/api/replays/${replay.token}/approvals.json`)
    .then(function(response){
      // console.log(response);
      setApprovals(response.data.approvals)
      setLoaded(true);

    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      // always executed
    });
  }

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setDisabled(true);  
    if (emails.length > 0) {

      const frequency = document.getElementById("reminders").value
      const parsedFrequency = parseInt(frequency);
      debugger
        axios.post(`/api/replays/${replay.token}/feedback_links`, {
          emails: emails, 
          message: document.getElementById("message").value.length > 0 ? document.getElementById("message").value : null,
          remind: frequency == 'null' ? false : true, // boolean 
          remind_frequency: frequency == 'null' ? null : parsedFrequency // integer
        })
        .then(function(response){
          // console.log(response);
          if(response.data.success){
            fetchSent();
            setEmails([])
            setShowMessage(false);
            notice("Succesfully sent!")
          } else {
            notice(response.data.error)
          }
        })
        .catch(function(error){
          console.log(error)
          notice("An error occured")
        })
        .then(function () {
          setDisabled(false);
        });
    } else {
      notice("Enter an email")
    } 
      
  }

  const sent_emails = sent.map((item) => {
    return (
      <div className="display-row justify-content-between mb-15 mt-15 font-13">
        <div className="font-12">
          {item.email}
        </div>
        <div className="display-row">
          {item.remind == true && 
            <div className="mr-8 font-12">Reminding every {item.remind_frequency == 1 ? 'day' : ` ${item.remind_frequency} days`}</div>
          }

          {item.approval != null && 
            <React.Fragment>
              <ReactTooltip effect="solid" backgroundColor="black" textColor="white" delayShow={200}/>
              {item.approval.comment != null && 
                <i class="fal fa-comment-alt-lines" data-tip={item.comment} style={{padding: "3px 10px"}}></i>
              }
              {item.approval.approved ? (
                <i className="fas fa-check color-green" style={{padding: "3px 10px"}}></i>
              ):(
                <i className="fas fa-times color-red" style={{padding: "3px 10px"}}></i>
              )}
            </React.Fragment>
          }
          <Edit feedbackLink={item} replay_id={replay.token} refetchData={fetchSent}/>
        </div>
      </div>
    )
  })

  const approval_list = approvals.map((item) => {
    return (
      <Approved item={item} />
    )
  })

  return(
    loaded && 
    <React.Fragment>
      <Tabs className="color-black-always" activeIndex={defaultIndex}>
        <TabList>
          <Tab>Share</Tab>
          <Tab>Settings</Tab>
        </TabList>

        <TabPanel>
          <form onSubmit={handleSubmit} className="row no-margin color-black-always" id="review_link">
            <div className="field">
              <h5 className="font-600">Request approval</h5>
              <ReactMultiEmail
                placeholder='Add email...'
                emails={emails}
                onChange={(_emails) => {
                  setTyping(true);
                  setEmails(_emails);
                }}
                autoFocus={true}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                getLabel={(email, index, removeEmail) => {
                  return (
                    <div data-tag key={index}>
                      <div data-tag-item>{email}</div>
                      <span data-tag-handle onClick={() => removeEmail(index)}>
                        ×
                      </span>
                    </div>
                  );
                }}
              />
            </div>

            {/* {typing && <h5 className="font-600 color-blue font-13 cp no-margin mb-10" onClick={() => setShowMessage(!showMessage)}>Add a message</h5>} */}

            {emails.length > 0 && 
              <React.Fragment>
                <textarea rows="2" class="animated fadeIn form-control mb-20" name="message" id="message" placeholder="Write a message to the reviewer..." style={{maxWidth: "100%"}}/>
                <div className="field">
                  <select name="reminders" id="reminders" className="form-control form-control-small">
                    <option value="null">
                      Don't send reminders
                    </option>
                    <option value="1">                    
                      Everyday
                      </option>
                    <option value="2">
                      Every two days
                    </option>
                    <option value="3">
                      Every three days
                    </option>
                    <option value="7">
                      Once a week
                    </option>
                  </select>
                </div>
              
                <div className="field flex" style={{justifyContent: "end"}}>
                  <button type="submit" disabled={disabled} className="btn btn-small btn-primary">{t("Submit")}</button>
                </div>
              </React.Fragment>
            }
          </form>

          <React.Fragment>
            {approvals.length > 0 &&
            <div className="field">
              {approval_list}    
            </div>
            }

            {sent.length > 0 &&
              <div className="padding-10 border-all border-radius font-13 mb-25">
                <div className="cp display-row" onClick={() => setShowShared(!showShared)}>
                  {showShared ?
                    <i class="fas fa-chevron-down animated fadeIn mr-8 color-4"></i>
                    :
                    <i class="fas fa-chevron-right animated fadeIn mr-8 color-4"></i>
                  }
                  <div className="display-row justify-content-between" style={{width: "100%"}}>
                    <div>Requested approval</div>
                    <div>({sent.length})</div>
                  </div>
                </div>
                {showShared &&
                  <div className="animated fadeIn">
                    {sent_emails}
                  </div>
                }
              </div>
            }
          </React.Fragment>

          <div className="text-center border-top" style={{paddingTop: "25px"}}>
            <div class="input-group">
              <input type="text" class="form-control" defaultValue={`${window.location.origin}/replays/${replay.token}`} disabled={true}/>
              <span class="input-group-btn">
              <button class="btn btn-primary disable-left-border-radius" type="button" onClick={() => {
                navigator.clipboard.writeText(`${window.location.origin}/replays/${replay.token}`)
                  .then(() => {
                    setCopied(true);
                    setTimeout(function(){setCopied(false)}, 2000);
                  })
              }}>
                {
                  copied ? (
                    <React.Fragment><i class="fal fa-check mr-5"></i> {t("Copied")}</React.Fragment>
                  ) : (
                    <React.Fragment><i class="fal fa-copy mr-5"></i> {t("Copy")}</React.Fragment>
                  )
                }
              </button>
                
              </span>
            </div>
          </div>
        </TabPanel>

        <TabPanel>
          <SettingsTab replay={replay} autoClose={false} refetchData={refetchData} />
        </TabPanel>
      </Tabs>
    </React.Fragment>
  )
}

export default Share