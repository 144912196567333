import React from "react";
import NoSsr from "@material-ui/core/NoSsr";
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from "@material-ui/core/styles";
import { ThemeProvider as SCThemeProvider } from "styled-components";

export const theme = {
  colors: {
    red: "#F04747",
    yellow: "#F5C400",
    green: "#00C191",
    blue: "#4169e1",
    lightGray: "#CAD4E7",
    background: "#1c1e2a",
  },
};

const muiTheme = createTheme({
  spacing: 8,
  palette: {
    type: "dark",
    primary: {
      main: theme.colors.blue,
    },
    secondary: {
      main: theme.colors.yellow,
    },
    background: {
      paper: "#fff"
    }
  },
  typography: {
    fontFamily: "Open Sans",
    htmlFontSize: 10,
  },
  colors: theme.colors,
});

const ThemeProvider = ({ children }) => (
  <NoSsr>
    <MuiThemeProvider theme={muiTheme}>
      <SCThemeProvider theme={muiTheme}>{children}</SCThemeProvider>
    </MuiThemeProvider>
  </NoSsr>
);

export default ThemeProvider;
