import React from 'react';
import ReactTooltip from 'react-tooltip';
import { NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import ProjectDropdown from "./ProjectDropdown.js";
import EmbedDropdown from "../Apps/Embeds/EmbedDropdown.js";
import { useHotkeys } from 'react-hotkeys-hook'

const Project = ({project, fetchProjects, privateProject, index}) => {
  const history = useHistory();
  const match = useRouteMatch();
  useHotkeys(
    `shift+${index+1}`,
    () => {
      if (!project.private) {
        history.push(`${(project.project_access_type === "Client" || location.href.includes(`/portal/o/`)) ? "/portal" : ""}/o/${project.organization.token}/projects/${project.token}/tasks`);
      }
    },
    [project.private, project.project_access_type, project.organization.token, project.token]
  );

  const embeds_list = project?.embeds?.map(embed => {
    return(
      <div className="display-flex align-items-center hover-parent-opacity">
        <li className="daas-platform-links-li animate sidebar-text-color side-nav-submenu-li animated fadeInUp fast-delay-1 width-100-percent">
          {embed.direct_link ? ( 
            <a activeClassName="background-active" href={embed.direct_link} target="_blank" className="daas-platform-links-a sidebar-text-color side-nav-submenu">
              {embed.title}
            </a>
          ):(
            <NavLink activeClassName="background-active" to={`/o/${match.params.organization_id}/projects/${project.token}/embeds/${embed.token}`} exact className="daas-platform-links-a sidebar-text-color side-nav-submenu">
              {embed.title}
            </NavLink>
          )}

        </li>
        <EmbedDropdown embed={embed} project_id={project.token} refetchData={fetchProjects} className="pull-right mr-5"/>
      </div>
    )
  })

  
  return (
    // show && 
    <>
      <li className="daas-platform-links-li color-1 hover-parent-opacity hover-parent"  data-html={true} data-tip={index+1 > 9 ? "" : `<div class="tooltip-keybind-wrapper"><span class='tooltip-keybind'>SHIFT</span> + <span class='tooltip-keybind'>${index + 1}</span></div>`}>
        <NavLink activeClassName="background-active" to={`${(project.project_access_type === "Client" || location.href.includes(`/portal/o/`)) ? "/portal" : ""}/o/${project.organization.token}/projects/${project.token}/tasks`} className={`daas-platform-links-a sidebar-text-color truncate ${location.href.includes(project.token) ? "background-active" :""}`}>
          <span className="sidebar-text-color">
            <i class="sidebar-text-color fas fa-grip-vertical projects-move-icon"></i>
          </span> 
          {project.status?.paused ? (
            <i data-tip="Paused" className="fas fa-pause color-yellow project-list-avatar font-20 text-center"></i>
          ) : project.status?.cancel_at_period_end && project.status?.expired ? (
            <i data-tip="Cancelled" className="fas fa-times color-red project-list-avatar font-20 text-center"></i>
          ) : (
            <img src={project.avatar} className="project-list-avatar" />
          )}
          {project.title} {project.unreads && <span className="project-badge-notification"></span>} {privateProject && <i class="fas fa-lock opacity-6 sidebar-text-color font-10 ml-5"></i>}
        </NavLink>
        
        {!location.pathname.includes("/portal/o/") && 
          <div class="btn-group">
            <ProjectDropdown project={project} refetchData={fetchProjects}/>
          </div>
        }
      </li>
      
      {
        (project?.embeds?.length > 0 && location.pathname.includes(project.token) && !location.pathname.includes("/portal/o/")) && 
        <div className="animated fadeIn">
          {embeds_list}
        </div>
      }
    </>
  )
}

export default Project