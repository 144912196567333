import React , { useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom'
import axios from "axios"; 

const Partnerships = (props) => {
  const match = useRouteMatch();
  const [organizations, setOrganizations] = useState([]);
  
  useEffect(() => {
    fetchPartnership();
  }, []);

  const fetchPartnership = () => {
    axios.get(`/${match.params.partnership_id}.json`)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        setOrganizations(response.data.organizations)
      } else {
        notice("An error occured")
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      // always executed
    });
  }

  const organizations_map =  organizations.map((organization, index) => {
    return (
      <tr>
        <th scope="row">{index + 1}</th>
        <td>{organization.title}</td>
        <td><a href={`/@${organization.subdomain}`} target="_blank">Profile</a></td>
        <td>{organization.approved && "True"}</td>
      </tr>
    )
  })

  return(
    <React.Fragment>
      <div className="container" style={{marginTop: "30px"}}>
        <div className="background-1 default-padding">
          <h2>Signups</h2>
          <table class="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Name</th>
                <th scope="col">Profile</th>
                <th scope="col">Approved</th>
              </tr>
            </thead>
            <tbody>
              {organizations_map}
            </tbody>
          </table>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Partnerships